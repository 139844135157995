<template>
  <div>
    <el-tabs type="border-card">
      <el-tab-pane>
        <span slot="label"><i class="el-icon-s-promotion"></i> 工单流程设置</span>
        <setOrder />
      </el-tab-pane>
      <!-- <el-tab-pane label="消息中心"
        ><span slot="label"
          ><i class="el-icon-s-order"></i> 合同设置</span
        ></el-tab-pane
      >
      <el-tab-pane label="角色管理"
        ><span slot="label"
          ><i class="el-icon-folder-checked"></i> 回款设置</span
        ></el-tab-pane
      > -->
    </el-tabs>
  </div>
</template>

<script>
import setOrder from "@/views/workorder/modules/customOrderCom/setOrder.vue";

export default {
  components: { setOrder },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
</style>