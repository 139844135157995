/**
 * customOrder
 * 自定义审批流
 */

import request from '@/utils/request'

// 查询
export const select = (data) => {
    return request({
        method: 'post',
        url: '/gmApprocal/select',
        data
    })
}

// 新增
export const add = (data) => {
    return request({
        method: 'post',
        url: '/gmApprocal/add',
        data
    })
}

// 删除
export const deleteOrder = (data) => {
    return request({
        method: 'post',
        url: '/gmApprocal/delete',
        data
    })
}