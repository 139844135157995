<template>
  <el-card id="qj">
    <header>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <el-button
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="handleSearch"
              size="mini"
              class="searchBtn"
              >新增审批流</el-button
            >
          </div>
        </div>
        <div class="right"></div>
      </div>
    </header>
    <hr>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <!-- 多选按钮 -->
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column label="审批工单类型" align="center">
          <template #default="scope">
            <div>{{ setShowTypeName(scope.row.bsf) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(v, i) in dataLabel"
          :key="i"
          :label="v.label"
          :prop="v.name"
          align="center"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" width="300" align="center">
          <template #default="scope">
            <el-button type="danger" @click="planBtn(scope.row)" size="mini"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </main>
    <!-- 审批人&抄送人 -->
    <el-dialog
      title="新增自定义审批流"
      :visible.sync="outerVisible"
      @close="closeDialog"
    >
      <el-divider content-position="left">选择工单类型</el-divider>
      <el-select v-model="orderMan" filterable placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-divider content-position="left">添加审批人</el-divider>
      <el-cascader-panel
        :options="options1"
        v-model="sprVal"
        ref="cascaderBox"
      ></el-cascader-panel>
      <div class="spr-selected" style="margin-top: 10px">
        <div
          v-for="(item, i) in sprValArr"
          :key="item.value"
          style="display: inline-block"
        >
          <el-button style="margin-bottom: 10px; position: relative"
            >{{ item.label
            }}<i
              class="el-icon-circle-close"
              @click="delUser(i)"
              style="color: red; position: absolute; top: -20%; right: -10%"
            ></i
          ></el-button>
          <span
            style="font-weight: bold; font-size: 18px; color: rgb(65, 187, 132)"
            v-if="i != sprValArr.length - 1"
          >
            ——></span
          >
        </div>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="outerVisible = false">取消</el-button>
          <el-button type="primary" @click="orderAdd"
            >添加第
            <span style="font-weight: bold; font-size: 18px">{{
              sprValArr.length + 1
            }}</span>
            个审批人</el-button
          >
          <el-button type="primary" @click="orderSubmit">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();

import { select, add, deleteOrder } from "@/api/customOrder";
import { userList1 } from "@/api/user";
export default {
  data() {
    return {
      orderMan: null,
      options: [
        {
          id: 1,
          value: "1",
          label: "请假",
        },
        {
          id: 2,
          value: "2",
          label: "外出",
        },
        {
          id: 3,
          value: "3",
          label: "报销",
        },
        {
          id: 4,
          value: "4",
          label: "出差",
        },
        {
          id: 5,
          value: "6",
          label: "回款",
        },
        {
          id: 5,
          value: "7",
          label: "合同",
        },
      ],
      options1: [],
      sprVal: null,
      sprValArr: [],
      outerVisible: false,
      dataLabel: [
        {
          label: "创建时间",
          name: "createDate",
        },
        {
          label: "审批人流程",
          name: "userList",
        },
      ],
      auditRemark: null,
      planVisible: false,
      tableData: [], //表格内绑定的数据
    };
  },
  async created() {
    this.parentId = localStorage.getItem("id");
    await this.getList();
    await this.orderBtn();
  },
  methods: {
    // 选择审批人确定
    async orderSubmit() {
      if (!this.orderMan) {
        Message.warning("请选择工单类型");
        return false;
      }
      if (this.sprValArr.length == 0) {
        Message.warning("请添加审批人");
        return false;
      }

      let params = this.sprValArr.map((v) => {
        return {
          bsf: this.orderMan, //标识符1：请假  2：外出  3：报销  4：出差    5：退费 6：回款 7：合同
          userId: v.value, //员工id
          userName: v.label, //员工名称
          companyId: this.parentId, //公司id
        };
      });

      let res = await add(params);
      console.log("auditUserId", res.data);
      Message.success(res.data.message);
      this.outerVisible = false;
      this.getList();
    },
    // 添加审批人
    orderAdd() {
      let arr = this.$refs.cascaderBox.getCheckedNodes();
      this.sprValArr.push(arr[0].data);
      // 去重
      let map = new Map();
      for (let item of this.sprValArr) {
        if (!map.has(item.value)) {
          map.set(item.value, item);
        }
      }
      this.sprValArr = [...map.values()];
      // console.log("this.sprValArr",this.sprValArr);
    },

    // 选择审批人的数据获取
    orderBtn() {
      let params = {};
      let res = userList1(params);
      res.then((res) => {
        let arr = res.data.data.map((v) => {
          return { value: v.depart_id, label: v.depart_name, children: [] };
        });

        // 去重
        let map = new Map();
        for (let item of arr) {
          if (!map.has(item.value)) {
            map.set(item.value, item);
          }
        }
        let newArr = [...map.values()];
        newArr.forEach((v) => {
          res.data.data.forEach((v2) => {
            if (v.value == v2.depart_id) {
              v.children.push({ value: v2.user_id, label: v2.realname });
            }
          });
        });
        this.options1 = newArr;
      });
    },
    // 清空审批人弹出框
    closeDialog() {
      this.sprValArr = [];
      this.orderMan = null;
    },
    setShowTypeName(bsf) {
      //标识符1：请假  2：外出  3：报销  4：出差    5：退费 6：回款 7：合同
      if (bsf == 1) {
        return "请假";
      } else if (bsf == 2) {
        return "外出";
      } else if (bsf == 3) {
        return "报销";
      } else if (bsf == 4) {
        return "出差";
      } else if (bsf == 5) {
        return "退费";
      } else if (bsf == 6) {
        return "回款";
      } else if (bsf == 7) {
        return "合同";
      }
    },

    // 展示
    async getList() {
      this.tableData = [];
      let _this = this;
      for (let v = 1; v <= 7; v++) {
        if (v != 5) {
          let res = await select({ bsf: v });
          let arr = res.data.data || [];
          arr.sort((a, b) => a.hierarchy - b.hierarchy);
          let userList = arr.map((v) => {
            return v.userName;
          });
          let userIdList = arr.map((v) => {
            return { id: v.id };
          });
          let newArr;
          newArr = arr[0];
          if (newArr) {
            newArr.userList = userList.join("————>");
            newArr.userIdList = userIdList;
            this.tableData.push(newArr);
          }
        }
      }
    },
    // 重置
    reset() {
      this.status = null;
      this.getList();
    },
    // 新增审批流
    handleSearch() {
      this.outerVisible = true;
    },

    // 删除
    planBtn(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let params = row.userIdList;
          let res = await deleteOrder(params);
          // Message.error("失败提示");
          Message.success(res.data.message);
          this.getList();
        })
        .catch(() => {
          Message.info("已取消删除");
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-button--success,.el-button--primary {
    color: #FFF;
    background-color: #3278FE;
    border-color: #3278FE;
    &:hover{
      opacity: 0.7;
    }
}

</style>